import React from "react"
import { Link } from "gatsby"
import { YMaps, Map } from 'react-yandex-maps';
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contacts = ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <SEO title="Contacts" />
    <section id="contact" className="pb80 pt100">

      <YMaps> 
        <Map defaultState={{ center: [55.586562, 37.601840], zoom: 17 }} style={{ width: '100%', height: '100%', position: 'absolute' }} />
      </YMaps>


      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="section-title mb25">
              <h1>контакты</h1>
              <hr />
            </div>

          </div>

        </div>



        <div className="col-md-4 col-sm-4 col-xs-12">
          <div className="contact-info">
            <ul className="list-unstyled">
              <li>
                <h4><i className="fa fa-home"></i> Адрес</h4>
                <p>Москва, ул.Кирпичные Выемки, д.2, к.1</p>
              </li>
              <li>
                <h4><i className="fa fa-phone"></i> Телефон</h4>
                <p>+7 (995) 500-50-88</p>
              </li>
              <li>
                <h4><i className="fa fa-envelope"></i> EMAIL</h4>
                <p><a href="mailto:ooomaster77@yandex.ru">ooomaster77@yandex.ru</a></p>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-8 col-sm-8 col-xs-12">

        </div>

      </div>

    </section>
    <Link to="/">На главную страницу</Link>
  </Layout>
)

export default Contacts
